import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import StoreSelectSection from '../components/partials/landing/storeSelectSection';
import AdvantageSection from '../components/partials/landing/advantageSection';
import AdditionalQuestionSection from '../components/partials/landing/additionalQuestionSection';
const FrequentlyQuestionSection = dynamic(() => import('../components/partials/landing/frequentlyQuestionSection'), {
  ssr: false,
});
import StoreNavSection from '../components/partials/landing/storeNavSection';
import StoreElectronicsNavSection from '../components/partials/landing/storeElectronicsNavSection';
import ReviewsSection from '../components/partials/landing/reviewsSection';
import BestSellersSection from '../components/partials/landing/bestSellersSection';
import FeaturedProductsSection from '../components/partials/landing/featuredProductsSection';
import WorkDescriptionSection from '../components/partials/landing/workDescrSection';
import IframeModal from '../components/features/modals/iframe-modal';
import HowItWorks from '../components/features/modals/how-it-works';
import { HowItWorksStyles } from '../themes/iframe-modal';
import { useRouter } from 'next/router';
import FullheightIframe from '../components/features/modals/fullheight-iframe';
import Cookies from 'js-cookie';
import { getLocalStorage, setLocalStorage } from '../utils/storage/storage';
import { defaultQueryParamsForShop } from '../components/constants';
import useApproveModalStore from '../store/approve-modal';
import { constructInitMarketingData } from '../utils/utils';
import dynamic from 'next/dynamic';
import MetaHeader from '../components/common/MetaHeader';
import { useAuthModalStore } from '../store/auth-modal';

const uownSrc = process.env.NEXT_PUBLIC_UOWN_BASE_URL || '';
const approvalSrc = process.env.NEXT_PUBLIC_APPROVAL_BASE_URL || '';

function Index() {
  const [isChecked, setIsChecked] = useState(false);
  const [isShowLeaseModal, setIsShowLeaseModal] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isApproved, setIsApproved] = useState(false);

  const { isOpen, closeModal, fromFooter } = useApproveModalStore();
  const { currentCustomerData } = useAuthModalStore();
  const { providerForUnauthorized } = useSelector(state => state.app);

  const router = useRouter();

  const constructLeaseProviderUrl = () => {
    if (typeof window === 'undefined') return `${uownSrc}/?store_type=furniture`;

    const customerData = JSON.parse(localStorage.getItem('customerData'));
    const customerPhone = customerData?.customer?.phone;
    const customerProvider = customerData?.lease_providers[0]?.name;

    let analyticParamsQuery = constructInitMarketingData(
      'analytic_params',
      'furniture'
    );

    if (Cookies.get('DD_TOKEN') && !!customerPhone) {
      const uownUrl = `${customerProvider === 'katapult' ? approvalSrc : uownSrc}/buyontrust/${customerPhone}/items?${analyticParamsQuery}&lp=${customerProvider}`;
      return uownUrl;
    }

    // console.log(`${uownSrc}/?${analyticParamsQuery}`);
    return `${providerForUnauthorized?.provider === 'katapult' ? approvalSrc : uownSrc}/?${analyticParamsQuery}&lp=${providerForUnauthorized?.provider}`;
  };

  const handleCloseModal = () => {
    setIsOpenModal(false);
    closeModal();
    setLocalStorage('showHowItWorks', isChecked);
  };

  const handleCloseLeaseModal = () => {
    setIsShowLeaseModal(false);
    let newQuery = { ...router.query };
    let newQueryToString = '';
    if (new URLSearchParams(newQuery).toString().length) {
      newQueryToString = `?${new URLSearchParams(newQuery).toString()}`;
    } else {
      // Set default
      if (router.pathname === '/shop') {
        newQueryToString = defaultQueryParamsForShop; // '?search=&filters=%7B"categories"%3A%5B%5D%2C"price"%3A%7B"min"%3A0%2C"max"%3A0%7D%2C"materials"%3A%5B%5D%2C"colors"%3A%5B%5D%7D&page=1}';
      }
    }

    const newUrl = `${window.location.origin}${router.pathname}${newQueryToString}`;
    window.history.replaceState({}, '', newUrl);

    window.location.reload();
  };

  const handleOpenModal = () => {
    const showModal = getLocalStorage('showHowItWorks');
    if (fromFooter) {
      setIsOpenModal(true);
      return;
    }
    if (showModal === null || !showModal) {
      setIsOpenModal(true);
    } else {
      setIsShowLeaseModal(true);
    }
  };

  useEffect(() => {
    const customerData = localStorage.getItem('customerData');
    if (customerData) {
      const parsedData = JSON.parse(customerData);
      const leaseData = parsedData.lease_data || [];
      const approvedLease = leaseData.find(
        lease => (lease?.name === 'uown_furniture' || lease?.name === 'katapult') && lease?.application_status
      );
      if (approvedLease?.application_status === 'approved' && approvedLease?.approval_amount > 0) {
        setIsApproved(true);
        setLocalStorage('showHowItWorks', isChecked);
      }
    }
  }, [currentCustomerData]);

  useEffect(() => {
    if (isOpen) handleOpenModal();
  }, [isOpen]);

  return (
    <main className={'skeleton-body skel-shop-products'}>
      <MetaHeader title={'Landing'}/>
      <div>
        <StoreSelectSection />
        <AdvantageSection />
        <StoreNavSection />
        <WorkDescriptionSection id="how-it-works" />
        <StoreElectronicsNavSection />
        <FeaturedProductsSection />
        <BestSellersSection />
        <ReviewsSection />
        <FrequentlyQuestionSection />
        <AdditionalQuestionSection />

        <IframeModal
          isOpen={isOpenModal}
          onRequestClose={handleCloseModal}
          styles={HowItWorksStyles}
        >
          <HowItWorks
            handleClose={handleCloseModal}
            isChecked={isChecked}
            setIsChecked={setIsChecked}
            setIsOpenModal={setIsOpenModal}
            setIsShowLeaseModal={setIsShowLeaseModal}
          />
        </IframeModal>
        <IframeModal
          isOpen={isShowLeaseModal}
          onRequestClose={handleCloseLeaseModal}
        >
          <FullheightIframe src={constructLeaseProviderUrl()} />
        </IframeModal>
        {/*<a href="https://www.livechat.com/chat-with/18104166/" rel="nofollow">*/}
        {/*  Chat with us*/}
        {/*</a>*/}
        {/*, powered by{' '}*/}
        {/*<a*/}
        {/*  href="https://www.livechat.com/?welcome"*/}
        {/*  rel="noopener nofollow"*/}
        {/*  target="_blank">*/}
        {/*  LiveChat*/}
        {/*</a>*/}
        {/* <Footer/> */}
      </div>
    </main>
  );
}

export default Index;
